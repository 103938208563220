<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
              >
                <template v-slot:eventContent='arg'>
                  <div class="fc-event-time">
                    {{ arg.timeText }}
                  </div>
                  <div class="fc-event-title-container">
                    <div class="fc-event-title fc-sticky">
                      <b>{{ arg.event.title }}</b>
                    </div>
                  </div>
                  <div v-if="arg.event.extendedProps.client || arg.event.extendedProps.user">
                    <hr class="m-0">
                  </div>
                  <div v-if="arg.event.extendedProps.client">
                    <b>{{ $t('Client') }}:</b> {{ arg.event.extendedProps.client }}
                  </div>
                  <div v-if="arg.event.extendedProps.client">
                    <b>{{ $t('Customer') }}:</b> {{ arg.event.extendedProps.user }}
                  </div>
                </template>
              </full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>

let buttons = []

function buttonClick() {
  let currentView = this.view.type
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  userData.calendarView = currentView
  window.localStorage.setItem('userData', JSON.stringify(userData))
}

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormSelect,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {ref, onUnmounted, mounted, computed} from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";

import {required, alphaNum, email} from "@validations";
import FullCalendar from '@fullcalendar/vue'
import allLocales from '@fullcalendar/core/locales-all';

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import deLocale from "@fullcalendar/core/locales/de";
import enLocale from "@fullcalendar/core/locales/en-gb";
import moment from "moment";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,

    FullCalendar
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      loaded: true,
      calendarItems: null,
      currentItem: {},
      calendarOptions: {
        validRange: function () {
          let startDate = new moment()
          let endDate = new moment()
          startDate.subtract(7, "days")
          endDate.add(1, "year")

          return {
            start: startDate.format("YYYY-MM-DD"),
            end: endDate.format("YYYY-MM-DD")
          }
        },

        locale: deLocale,
        url: 'test.php',
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        datesSet: this.fetchEvents,
        allDaySlot: false,
        slotDuration: '00:30:00', // default is 30 min
        slotMinTime: '06:00:00',
        slotMaxTime: '22:00:00',
        height: "auto",
        events: [],
      },
      events: []
    }
  },

  mounted() {
    this.getEvents()
    this.setLocale()
    this.getCalendarViewInitial()
    this.setViewToStorage()

  },
  computed: {
    currentLocale() {
      return this.$i18n.locale
    }
  },
  watch: {
    currentLocale(value) {
      if (value === 'en') {
        this.calendarOptions.locale = enLocale
      }
      if (value === 'de') {
        this.calendarOptions.locale = deLocale
      }
    }
  },
  methods: {
    setViewToStorage() {
      const gridDayButton = document.querySelector('.fc-timeGridDay-button')
      const gridWeekButton = document.querySelector('.fc-timeGridWeek-button')
      const gridMonthButton = document.querySelector('.fc-dayGridMonth-button')
      const gridListMonthButton = document.querySelector('.fc-listMonth-button')
      buttons = [gridDayButton, gridWeekButton, gridMonthButton, gridListMonthButton]
      buttons.forEach((button) => {
        button.addEventListener("click", buttonClick.bind(this.$refs.refCalendar.getApi()));
      })
    },

    getCalendarViewInitial() {
      const userData = JSON.parse(window.localStorage.getItem('userData'))
      if (userData.hasOwnProperty('calendarView')) {
        this.$refs.refCalendar.getApi().changeView(userData.calendarView);
      }
    },

    setLocale() {
      if (this.currentLocale === 'en') {
        this.calendarOptions.locale = enLocale
      }
      if (this.currentLocale === 'de') {
        this.calendarOptions.locale = deLocale
      }
    },

    fetchEvents(args) {
    },
    getBookingTimes() {
      this.$http.get('/bookingtimes')
          .then(response => {
          })
    },
    getEvents() {
      this.$http.get('googlecalendar/events')
          .then(resp => (this.calendarOptions.events = resp.data))
          .catch(err => console.log(err.response.data));
    },
  },
  beforeDestroy() {
    buttons.forEach(button => button.removeEventListener('click', buttonClick))
  }
};
</script>


<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
td.fc-list-event-title{
  color: #6e6b7b ;
}
.dark-layout .fc .fc-scrollgrid-section-sticky > *{
  background: #2e2e30 !important;
}
.dark-layout td.fc-list-event-title{
  color:#b4b7bd ;
}
</style>
